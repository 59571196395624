import React, { useState } from "react";
import { create } from "ipfs-http-client";
import { Buffer } from "buffer";
import ReactPlayer from "react-player";
import "./App.css";
import previewImage from "./2024-header-logos-001upload.png";

const VideoPlayerWithPreview = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <div className="video-container">
      {!isPlaying ? (
        <div
          className="preview-image-container"
          onClick={handlePlay}
          style={{
            cursor: "pointer",
            position: "relative",
            display: "inline-block",
          }}
        >
          <img
            src={previewImage}
            alt="Video Preview"
            style={{
              width: "100%",
              maxWidth: "90%",
              display: "block",
              borderRadius: "8px",
            }}
          />
          <button
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "10px 20px",
              fontSize: "16px",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            ▶ Play
          </button>
        </div>
      ) : (
        <ReactPlayer
        
        // url="https://freeweb3.infura-ipfs.io/ipfs/QmPQ4byd2WM9giiLrwtMDgscdtgyzXFfyDPkxNTFEF2Pyv"
        url="https://freeweb3.infura-ipfs.io/ipfs/QmYgpRXHXvftCiNmfua7ktFdNkwh4YBSSDsWtzE7SZUfgK"
        // url="https://freeweb3.infura-ipfs.io/ipfs/QmUFZ9y3Qp1MJDnFdSyTjigZHnWguigqLXMArDyXRefvxp"
        // url="https://freeweb3.infura-ipfs.io/ipfs/QmNWs1ggT3Jmr6nFsBdqkjxvm26NKmNMRKBXWmZFsscjcD"
        // url="https://freeweb3.infura-ipfs.io/ipfs/QmPXKWM5tjJLZcNQ8AYBJVCL4aRQ1hF6run7b3bBJdVKhq"
        // url="https://freeweb3.infura-ipfs.io/ipfs/QmXR3rxDViUGE8Eyx3ca79hc8S1tuCk3CKfwdPSprNye6P" 
        // url="https://freeweb3.infura-ipfs.io/ipfs/QmZJA219Pb77LnQpiCVfWP1br1whxMaUigKzStrGZ4QKdH" 
        // url="https://freeweb3.infura-ipfs.io/ipfs/QmQ72STYwmuyTsQFTBHK5zp54H4VFTamPnn9sg9NqVW5cY"
          controls
          playing
          width="90%"
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
              },
            },
          }}
        />
      )}
    </div>
  );
};

// IPFS client configuration
const projectId = "2DBM8M1VMImQ5vl4IQxYQzI8uKg";
const projectSecret = "f34bbedac4f01e7e79a9c99d04ba08cf";
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

const client = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  apiPath: "/api/v0",
  headers: { authorization: auth },
});

function App() {
  const [fileUrl, setFileUrl] = useState("");
  const [fileMetadata, setFileMetadata] = useState(null);
  const [darkMode, setDarkMode] = useState(false);

  const formatFileSize = (bytes) => {
    if (bytes < 1024) return `${bytes} bytes`;
    if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)} KB`;
    return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const metadata = {
      name: file.name,
      type: file.type || "Unknown file type",
      size: formatFileSize(file.size),
      lastModified: new Date(file.lastModified).toLocaleString(),
    };

    setFileMetadata(metadata);

    try {
      const added = await client.add(file);

      const urlVariants = [
        `https://freeweb3.infura-ipfs.io/ipfs/${added.path}`,
        `https://freeweb3.infura-ipfs.io/ipfs/${
          added.path
        }${file.name.substring(file.name.lastIndexOf("."))}`,
        `https://ipfs.infura.io/ipfs/${added.path}`,
        `https://ipfs.infura.io/ipfs/${added.path}${file.name.substring(
          file.name.lastIndexOf(".")
        )}`,
      ];

      console.log("URL Variants:", urlVariants);

      for (const url of urlVariants) {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Accept: "image/*",
              "Access-Control-Allow-Origin": "*",
            },
          });

          if (response.ok) {
            console.log("Successfully found working URL:", url);
            setFileUrl(url);
            return;
          }
        } catch (fetchError) {
          console.error("Error fetching URL:", url, fetchError);
        }
      }

      throw new Error("No working URL found");
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };




  const downloadFile = async () => {
    if (!fileUrl || !fileMetadata) return;

    try {
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error("Network response was not ok");

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const extension = fileMetadata.name.substring(fileMetadata.name.lastIndexOf("."));
      link.download = `${fileUrl.split("/").pop()}${extension}`;
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  const renderFilePreview = (fileUrl) => {
    if (!fileUrl) return null;

    return (
      <div>
        <img
          src={fileUrl}
          alt="Uploaded File"
          className="uploaded-file"
          crossOrigin="anonymous"
          onError={(e) => {
            console.error("Image load error", e);
          }}
          style={{
            maxWidth: "100%",
            maxHeight: "400px",
            border: "2px solid red",
          }}
        />
        {fileMetadata && (
          <div className="file-metadata">
            <h3>File Metadata</h3>
            <p>
              <strong>Name:</strong> {fileMetadata.name}
            </p>
            <p>
              <strong>Type:</strong> {fileMetadata.type}
            </p>
            <p>
              <strong>Size:</strong> {fileMetadata.size}
            </p>
            <p>
              <strong>Last Modified:</strong> {fileMetadata.lastModified}
            </p>
<br/>
            <div className="list">
          <div className="list-item-darkgray">
            <strong>Reminder about IPFS content:</strong> IPFS uses a public,
            distributed network where files are hashed and visible to everyone.
            Be cautious when uploading sensitive data.
          </div>
          <div className="list">
            <div className="list-item-red">
              <strong> Public Visibility:</strong> Anything uploaded is publicly
              accessible by anyone with the hash. Encrypt sensitive files before
              uploading for added security.
            </div>
          </div>
        </div>

        <div className="list">
          <div className="list-item-darkgray">
            With these precautions in mind, feel free to explore the
            <a
              href="https://image.encrypt.freeweb3.com/"
              className="link"
              target="_blank"
              rel="noreferrer"
              data-tooltip="IPFS is a public, distributed system. Encrypt sensitive files and avoid uploading private content."
            >
              {" "}
              image.encrypt.freeweb3.com
            </a>{" "}
            site and experience the power of encrypted files to IPFS firsthand.
          </div>
        </div>


        <div className="center-button-container">
  <button onClick={downloadFile} className="center-button">
    Download Renamed File
  </button>
</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`App ${darkMode ? "dark-mode" : "light-mode"}`}>
      <main className="container">
        <header className="header">
          <h1>IPFS Upload</h1>
          <button
            onClick={() => setDarkMode(!darkMode)}
            className={`mode-toggle ${darkMode ? "dark" : "light"}`}
          >
            {darkMode ? " Use Light Mode" : " Use Dark Mode"}
          </button>
        </header>

        <VideoPlayerWithPreview />
        <p className="instructions">Select a file to upload to IPFS.</p>

        <label className="file-upload">
          <input type="file" onChange={handleFileChange} />
          <span>Choose File</span>
        </label>

        {fileUrl && (
          <div className="result">
            <p>File URL:</p>
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              {fileUrl}
            </a>
          </div>
        )}

        {renderFilePreview(fileUrl)}

        <br/>
        <div className="list">
          <div className="list-item-darkgray">
            <strong>
            </strong> This IPFS uploader connects to Infura's dedicated gateway.
          </div>
          <div className="list-item">
            <strong>💡 Note:</strong> Ensure the file type is supported.
          </div>
        </div>


        
      </main>

      <footer className="footer">
        <p>
          <a href="https://ipfs.tech" target="_blank" rel="noopener noreferrer">
            Powered by IPFS
          </a>{" "}
          and{" "}
          <a href="https://infura.io" target="_blank" rel="noopener noreferrer">
            Infura
          </a>
        </p>
        <p>
          © 2025 <strong>Web3 Legal Engineering</strong>. All rights reserved.
        </p>
        <p>
          <a href="https://terms.freeweb3.com" className="footer-link">
            📜 W3LE Terms
          </a>{" "}
          |{" "}
          <a href="https://privacy.freeweb3.com" className="footer-link">
            🔒 W3LE Privacy
          </a>
        </p>
      </footer>
    </div>
  );
}

export default App;
